<template>
    <settingLayout>
        <section class="wrap_s_block">
            <div class="title_section">
                <h2 class="name_section">Privacy</h2>
            </div>
            <div class="body_ privacy">
                <h4>Under construction</h4>
            </div>
        </section>
    </settingLayout>
</template>

<script>
import settingLayout from "@/layouts/settingLayout";

export default {
    name: 'privacy',
    components: {
        settingLayout
    },
}
</script>
